import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`How to use`}</h2>
    <p>{`IBM Video Streaming provides an example PHP library for some basic API calls. Here you can find what’s needed to run the example calls and also download the library.`}</p>
    <h3>{`Prerequisites`}</h3>
    <p>{`You need PHP, and Composer to install the dependencies. The dependencies are defined in the composer.json file.`}</p>
    <p>{`If you have the dependencies installed, you can run the script with the following command:`}</p>
    <p><inlineCode parentName="p">{`php ustream_api.php`}</inlineCode></p>
    <p>{`You need a IBM Video Streaming account with API credentials, and you have to specify:`}</p>
    <ul>
      <li parentName="ul">{`your username`}</li>
      <li parentName="ul">{`your password`}</li>
      <li parentName="ul">{`your client id`}</li>
      <li parentName="ul">{`your client secret`}</li>
      <li parentName="ul">{`a video id`}</li>
    </ul>
    <p>{`At certain points in the script you will notice capitalized texts between angle brackets, like `}<inlineCode parentName="p">{`<VIDEO ID>`}</inlineCode>{`. That’s what needs to be changed to actual user info.`}</p>
    <p>{`You also need to specify the name of the video file you want to upload. The video must be in the same folder as the script.`}</p>
    <h3>{`Library contents`}</h3>
    <p>{`The PHP library contains examples for the following endpoints:`}</p>
    <ul>
      <li parentName="ul">{`Authentication`}</li>
      <li parentName="ul">{`List channels`}</li>
      <li parentName="ul">{`List videos`}</li>
      <li parentName="ul">{`Upload a video`}</li>
      <li parentName="ul">{`Download a video`}</li>
      <li parentName="ul">{`Request a downloadable video`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://github.com/ustream/api-docs/raw/gh-pages/assets/ustream_api_example.zip"
      }}>{`You can download the library here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      